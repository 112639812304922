<template>
    <div class="page-body">
        <div class="page-header">
            <div class="crumbs">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>
                        <i class="el-icon-s-management">付款申请</i>
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="table-body">

        </div>
        <div class="page-footer">
            <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" @handlePageChange="pageChange"/>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                multipleSelection: [],
                delList: [],
                // totalPage:0,
                totalPage: 30,
                option: {
                    pageIndex: '',
                    pageSize: 10,
                    currentPage: 2
                },
            }
        },
        methods: {
            pageChange(option) {
                this.option.currentPage = 1;
                alert(option)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-body {
        height: 100%;
        width: 100%;
    }

    .page-footer {
        bottom: 10px;
        position: absolute !important;
        //  border: 1px red solid;
        //right: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
